import React from "react";
import {
  PhoneIcon
} from "@heroicons/react/24/solid";
import MaskedInput from "./text-mask/reactTextMask";

export function PhonePicker(props) {
  const { success, error, ...rest } = props

  const getSuccessErrorColor = () => {
    if (success != null && success) {
      return "border-green-500 focus:border-green-500"
    }
    else if (error != null && error) {
      return "placeholder-red-500 border-red-500 focus:border-red-500"
    }
    else {
      return "placeholder-blue-gray-400 !border-neutral-400 focus:border-green-500";
    }
  }

  return (
    <div className="relative w-full min-w-[200px] h-11">
      <div className="grid place-items-center absolute text-blue-gray-500 top-2/4 right-3 -translate-y-2/4 w-6 h-6">
        <PhoneIcon className="h-5 w-5 text-base-content/100" />
      </div>
      <MaskedInput
        placeholder="e.g. (082) 498-1364"
        {...rest}
        mask={['(', /[0-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        showMask={false}
        guide={false}
        className={`peer w-full h-full 
                bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 
                focus:outline-0 disabled:bg-base-100 disabled:border-0 disabled:text-base-content transition-all
                
                border focus:border-2 
                text-base px-3 py-3 rounded-md !pr-9 
                bg-base-100 text-base-content/100 
                hover:bg-neutral-10 
                focus:bg-neutral-10 
                ${getSuccessErrorColor()}`}
      />

    </div>)
}